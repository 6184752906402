import {
  IMarathonGetParams,
  IMarathonParticipantsGetParams,
  IMarathonReq,
  IMarathonSpaceCalendarReqUpdate,
  IMarathonTeamsListUpdateReq,
  IGivePrizesUserReq,
  IGivePrizesTeamReq,
  IMarathonMemberRemoveReq,
} from 'types';
import { http, methods } from './config';

export const getMarathons = (params: IMarathonGetParams) => {
  const config = {
    method: methods.get,
    url: `/marathon/space`,
    params,
  };
  return http(config);
};

export const getMarathon = (marathonId: number | string) => {
  const config = {
    method: methods.get,
    url: `/marathon/space/${marathonId}`,
  };
  return http(config);
};

export const createMarathon = (data: IMarathonReq) => {
  const config = {
    method: methods.post,
    url: `/marathon/space`,
    data,
  };
  return http(config);
};

export const updateMarathon = (data: IMarathonReq) => {
  const config = {
    method: methods.put,
    url: `/marathon/space/${data.id}`,
    data,
  };
  return http(config);
};

export const deleteMarathon = (marathonId: number) => {
  const config = {
    method: methods.delete,
    url: `/marathon/space/${marathonId}`,
  };
  return http(config);
};

export const updateMarathonCalendar = (
  data: IMarathonSpaceCalendarReqUpdate,
) => {
  const config = {
    method: methods.put,
    url: `/marathon/calendar`,
    data,
  };
  return http(config);
};

export const getMarathonUserParticipants = (
  params: IMarathonParticipantsGetParams,
) => {
  const config = {
    method: methods.get,
    url: `/marathon/member`,
    params,
  };
  return http(config);
};

export const getMarathonTeamParticipants = (
  params: IMarathonParticipantsGetParams,
) => {
  const config = {
    method: methods.get,
    url: `/marathon/team`,
    params,
  };
  return http(config);
};

export const updateMarathonTeamParticipants = (
  data: IMarathonTeamsListUpdateReq,
) => {
  const config = {
    method: methods.post,
    url: `/marathon/team`,
    data,
  };
  return http(config);
};

export const givePrizesUser = (data: IGivePrizesUserReq) => {
  const config = {
    method: methods.post,
    url: `/marathon/member/set_prizes`,
    data,
  };
  return http(config);
};

export const givePrizesTeam = (data: IGivePrizesTeamReq) => {
  const config = {
    method: methods.post,
    url: `/marathon/team/set_prizes`,
    data,
  };
  return http(config);
};

export const removeUserFromMarathon = (data: IMarathonMemberRemoveReq) => {
  const config = {
    method: methods.patch,
    url: `/marathon/member/remove`,
    data,
  };
  return http(config);
};

import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getMarathons,
  getMarathon,
  createMarathon,
  updateMarathon,
  deleteMarathon,
  updateMarathonCalendar,
  getMarathonUserParticipants,
  getMarathonTeamParticipants,
  updateMarathonTeamParticipants,
  givePrizesTeam,
  givePrizesUser,
  removeUserFromMarathon,
} from 'api';
import {
  IMarathonSpaceSingle,
  IMarathonGetParams,
  IMarathonRes,
  IMarathonParticipantsGetParams,
  IMarathonParticipantTeam,
  IMarathonParticipantsUserRes,
  IMarathonParticipantsTeamRes,
  IGivePrizesCommonReq,
  IGivePrizesUserReq,
  IGivePrizesTeamReq,
} from 'types';

export const useGetMarathons = (params: IMarathonGetParams) => {
  const { page, search } = params;
  return useQuery<IMarathonRes, unknown>(
    ['marathons', { page, search }],
    async () => {
      const res = await getMarathons(params);
      return res;
    },
    {
      retry: false,
      staleTime: Infinity,
    },
  );
};

export const useGetMarathon = (marathonId: number | string) => {
  if (!marathonId)
    return useQuery(['marathons', 'marathonId', marathonId], () => {
      return Promise.resolve(null);
    });
  return useQuery<IMarathonSpaceSingle>(
    ['marathons', 'marathonId', marathonId],
    async () => {
      const res = await getMarathon(marathonId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 60,
      retry: false,
    },
  );
};

export const useCreateMarathon = () => {
  const queryClient = useQueryClient();
  return useMutation(createMarathon, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useUpdateMarathon = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMarathon, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useDeleteMarathon = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteMarathon, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useUpdateMarathonCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMarathonCalendar, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathonCalendar'] });
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useUpdateMarathonTeamsList = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMarathonTeamParticipants, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

export const useGivePrizes = (individual: boolean) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: IGivePrizesCommonReq) => {
      if (individual) {
        const convertedData: IGivePrizesUserReq = {
          ...data,
          winners: data.winners.map(winner => ({
            ...winner,
            user_id: winner.id,
          })),
        };
        return givePrizesUser(convertedData);
      }
      const convertedData: IGivePrizesTeamReq = {
        ...data,
        winners: data.winners.map(winner => ({
          ...winner,
          team_id: winner.id,
        })),
      };
      return givePrizesTeam(convertedData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['marathons'] });
      },
      onError: () => {},
    },
  );
};

export const useGetMarathonParticipants = (
  params: IMarathonParticipantsGetParams,
) => {
  const { individual } = params;
  const { data: dataUsers } = useGetMarathonUserParticipants(params);
  const { data: dataTeams } = useGetMarathonTeamParticipants(params);

  const data = individual ? dataUsers : dataTeams;
  return { data };
};

export const useGetMarathonUserParticipants = (
  params: IMarathonParticipantsGetParams,
) => {
  const { page, marathon_id, search, individual } = params;
  if (!individual) {
    return { data: { data: [], last_page: 0 } };
  }

  return useQuery<IMarathonParticipantsUserRes>(
    ['marathons', 'participants', { page, marathon_id, search, individual }],
    async () => {
      const res = await getMarathonUserParticipants(params);
      return res;
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 10,
    },
  );
};

export const useGetMarathonAllTeamParticipants = (
  marathon_id: string | number,
) => {
  const [teams, setTeams] = useState<IMarathonParticipantTeam[]>([]);

  const getAllTeams = async (pageCount: number) => {
    const queries = [];
    for (let i = 1; i <= pageCount; i++) {
      queries.push(
        getMarathonTeamParticipants({
          page: i,
          marathon_id,
        }),
      );
    }
    const res = await Promise.all(queries);
    const data = res.reduce((acc, item) => {
      return [...acc, ...item.data];
    }, []);
    setTeams(data);
  };

  const fetchTeams = async () => {
    const res = await getMarathonTeamParticipants({ page: 1, marathon_id });
    getAllTeams(res.last_page);
  };

  useEffect(() => {
    if (!marathon_id) return;
    fetchTeams();
  }, [marathon_id]);

  return {
    total: teams.length,
    data: teams,
  };
};

export const useGetMarathonTeamParticipants = (
  params: IMarathonParticipantsGetParams,
) => {
  const { page, marathon_id, search, individual } = params;
  if (individual) {
    return { data: { data: [], last_page: 0 } };
  }

  return useQuery<IMarathonParticipantsTeamRes>(
    ['marathons', 'participants', { page, marathon_id, search, individual }],
    async () => {
      const res = await getMarathonTeamParticipants(params);
      return res;
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 10,
    },
  );
};

export const useRemoveUserFromMarathon = () => {
  const queryClient = useQueryClient();
  return useMutation(removeUserFromMarathon, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['marathons'] });
    },
    onError: () => {},
  });
};

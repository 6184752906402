import { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import {} from 'hooks';
import {
  Drawer,
  DrawerFormBody,
  DrawerHeader,
  ModalTitle,
  StyledFlexBox,
  Button,
  StyledSwitch,
  FormItem,
  SearchSelectInput,
  VideoImageUpload,
} from 'UI';
import { CloseIcon, LeftArrowIcon } from 'assets';
import { IStoryItem, StoryTransitionType } from 'types';
import { StoryPreviewModal } from 'components';

import { StyledSwitchFormControl } from './styledComponents';
import { TRANSITION_TYPE_OPTIONS, TRANSITION_WITH_OBJECTS } from './utils';
import { ChooseTransitionObject } from './ChooseTransitionObject';

interface IAddEditStoryDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose?: 'add' | 'edit';
  item: IStoryItem;
  addItem: (_item: IStoryItem) => void;
  editItem: (_item: IStoryItem) => void;
}

export const AddEditStoryItemDrawer: FC<IAddEditStoryDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  item,
  addItem,
  editItem,
}) => {
  const [itemData, setItemData] = useState<IStoryItem>(item);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  useEffect(() => {
    setItemData(item);
    if (item.button_text) {
      setShowButton(true);
    }
  }, [item]);

  const handleFieldChange = <T extends keyof IStoryItem>(
    fieldName: T,
    newValue: IStoryItem[T],
  ) => {
    if (fieldName === 'transition_type') {
      setItemData(prevItemData => ({
        ...prevItemData,
        [fieldName]: newValue,
        transition_target: 0,
      }));
    }
    setItemData(prevItemData => ({
      ...prevItemData,
      [fieldName]: newValue,
    }));
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      addItem(itemData);
    } else {
      editItem(itemData);
    }
    onClose();
  };

  const ignoreChanges = () => {
    onClose();
  };

  const openPreview = () => {
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <StoryPreviewModal
        open={showPreview}
        onClose={closePreview}
        items={[itemData]}
      />
      <DrawerHeader>
        <StyledFlexBox>
          <LeftArrowIcon onClick={onClose} />
          <ModalTitle title='Редактор сториса' />
        </StyledFlexBox>
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <StyledFlexBox className='fullDivWidth'>
          <VideoImageUpload
            media={itemData.media}
            saveMediaLink={newMedia => handleFieldChange('media', newMedia)}
            inputId='storyItemMedia'
            title='Добавьте медиа'
            description='Загрузите изображение (1080 x 1920 px, до 1 мб) или видео (до 10 мб)'
          />
        </StyledFlexBox>

        <h2>О баннере</h2>
        <FormItem label='Кнопка'>
          <StyledSwitchFormControl
            label='Перейти'
            labelPlacement='start'
            control={
              <StyledSwitch
                checked={showButton}
                onChange={() => setShowButton(!showButton)}
              />
            }
          />
        </FormItem>
        {showButton && (
          <FormItem label='Текст кнопки'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите текст'
              name='text'
              value={itemData?.button_text}
              onChange={e => handleFieldChange('button_text', e.target.value)}
            />
          </FormItem>
        )}
        <FormItem label='Переход по клику'>
          <SearchSelectInput
            options={TRANSITION_TYPE_OPTIONS}
            value={itemData?.transition_type}
            onChange={newValue =>
              handleFieldChange(
                'transition_type',
                newValue as StoryTransitionType,
              )
            }
            variant='standard'
            placeholder='Выберите способ'
          />
        </FormItem>
        {TRANSITION_WITH_OBJECTS.includes(itemData?.transition_type) && (
          <ChooseTransitionObject
            placeType={itemData.transition_type}
            targetId={itemData.transition_target}
            choosePlace={newValue =>
              handleFieldChange('transition_target', newValue as number)
            }
            defaultSelectedObject={itemData.transition_object}
          />
        )}
        {itemData.transition_type === 'link' && (
          <FormItem label='Ссылка'>
            <TextField
              variant='standard'
              color='primary'
              placeholder='Введите ссылку'
              name='link'
              value={itemData?.transition_link}
              onChange={e =>
                handleFieldChange('transition_link', e.target.value)
              }
            />
          </FormItem>
        )}
      </DrawerFormBody>
      <StyledFlexBox>
        <Button onClick={openPreview} color='customGray' size='large'>
          Предпросмотр
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};

import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const StyledRightSideContainer = styled(Box)({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
});

export const StyledPopoverContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  '& button': {
    padding: '0.75rem 1rem',
    minWidth: '15rem',
    justifyContent: 'left',
  },
});

export const LoaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    animation: 'spin 4s linear infinite',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
});

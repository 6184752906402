import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  IPaginatedReqParams,
  INotificationsRes,
  INotificationFull,
} from 'types';
import {
  getNotifications,
  getNotification,
  createNotification,
  updateNotification,
  deleteNotification,
} from 'api';

export const useGetNotifications = (params: IPaginatedReqParams) => {
  const { page, search } = params;
  return useQuery<INotificationsRes, unknown>(
    ['notifications', { page, search }],
    async () => {
      const res = await getNotifications(params);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useGetNotification = (notificationId: string | number) => {
  if (!notificationId)
    return useQuery(['notifications', 'notificationId', notificationId], () => {
      return Promise.resolve(null);
    });
  return useQuery<INotificationFull>(
    ['notifications', 'notificationId', notificationId],
    async () => {
      const res = await getNotification(notificationId);
      return res;
    },
    {
      staleTime: 1000 * 60 * 10, // 10 minutes
      retry: false,
    },
  );
};

export const useCreateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(createNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
    onError: () => {},
  });
};

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(updateNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
    onError: () => {},
  });
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
    onError: () => {},
  });
};

import { FC, useState, useMemo, MouseEvent, useEffect, Fragment } from 'react';
import { Box, Popover } from '@mui/material';
import {
  useSearchMarathonSpacesForBanners,
  useSearchProductsForBanners,
  useSearchNewsForBanners,
} from 'hooks';
import { SearchInput, Button } from 'UI';
import { DeleteIcon } from 'assets';
import { COLORS } from 'utils';
import {
  IMarathonSpaceSingle,
  INews,
  IShowcaseOfferDiscount,
  NotificationTransitionType,
  IStoryItemTransitionObject,
} from 'types';

import {
  StyledContainer,
  StyledSearchComponent,
  StyledSelectProductItem,
  StyledSelectedProduct,
} from './styledComponents';
import { TRANSITION_OBJECTS_TYPES } from './utils';

interface IChooseTransitionObjectProps {
  placeType: NotificationTransitionType;
  choosePlace: (_id: string | number) => void;
  targetId: number | null;
  defaultSelectedObject?: IStoryItemTransitionObject;
}

export const ChooseTransitionObject: FC<IChooseTransitionObjectProps> = ({
  placeType,
  choosePlace,
  targetId,
  defaultSelectedObject,
}) => {
  const [showSearchComponent, setShowSearchComponent] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showcaseType, setShowcaseType] = useState<1 | 2>(2);
  const [selectedPlace, setSelectedObject] = useState<
    IStoryItemTransitionObject | undefined
  >(defaultSelectedObject);

  useEffect(() => {
    setSelectedObject(defaultSelectedObject);
  }, [defaultSelectedObject]);

  useEffect(() => {
    if (!targetId) {
      setSelectedObject(undefined);
    }
  }, [targetId]);

  const paramsToGetNews = useMemo(() => {
    return {
      search: searchValue,
    };
  }, [searchValue]);

  const paramsToGetOfferDiscounts = useMemo(() => {
    return {
      search: searchValue,
      type: showcaseType,
    };
  }, [searchValue, showcaseType]);

  const paramsToGetMarathons = useMemo(() => {
    return {
      search: searchValue,
      type: 1 as const,
    };
  }, [searchValue]);

  const {
    data: products,
    fetchNextPage: fetchNextPageProducts,
    hasNextPage: hasNextPageProducts,
    isFetchingNextPage: isFetchingNextPageProducts,
  } = useSearchProductsForBanners(paramsToGetOfferDiscounts);
  const {
    data: challenges,
    fetchNextPage: fetchNextPageChallenges,
    hasNextPage: hasNextPageChallenges,
    isFetchingNextPage: isFetchingNextPageChallenges,
  } = useSearchMarathonSpacesForBanners(paramsToGetMarathons);
  const {
    data: news,
    fetchNextPage: fetchNextPageNews,
    hasNextPage: hasNextPageNews,
    isFetchingNextPage: isFetchingNextPageNews,
  } = useSearchNewsForBanners(paramsToGetNews);

  useEffect(() => {
    if (placeType === TRANSITION_OBJECTS_TYPES.OFFER) {
      setShowcaseType(2);
    }
    if (placeType === TRANSITION_OBJECTS_TYPES.DISCOUNT) {
      setShowcaseType(1);
    }
  }, [placeType]);

  const openSearchComponent = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setShowSearchComponent(true);
  };

  const hideSearchComponent = () => {
    setShowSearchComponent(false);
    setAnchorEl(null);
  };

  const handleSearch = (search: string) => {
    setSearchValue(search);
  };

  const loadNextPage = () => {
    switch (placeType) {
      case TRANSITION_OBJECTS_TYPES.OFFER:
      case TRANSITION_OBJECTS_TYPES.DISCOUNT:
        fetchNextPageProducts();
        break;
      case TRANSITION_OBJECTS_TYPES.MARATHON_TEAM:
        fetchNextPageChallenges();
        break;
      case TRANSITION_OBJECTS_TYPES.NEWS:
        fetchNextPageNews();
        break;
      default:
        break;
    }
  };

  const getHasNextPage = () => {
    switch (placeType) {
      case TRANSITION_OBJECTS_TYPES.OFFER:
      case TRANSITION_OBJECTS_TYPES.DISCOUNT:
        return hasNextPageProducts;
      case TRANSITION_OBJECTS_TYPES.MARATHON_TEAM:
        return hasNextPageChallenges;
      case TRANSITION_OBJECTS_TYPES.NEWS:
        return hasNextPageNews;
      default:
        return false;
    }
  };

  const getIsLoadingNextPage = () => {
    switch (placeType) {
      case TRANSITION_OBJECTS_TYPES.OFFER:
      case TRANSITION_OBJECTS_TYPES.DISCOUNT:
        return isFetchingNextPageProducts;
      case TRANSITION_OBJECTS_TYPES.MARATHON_TEAM:
        return isFetchingNextPageChallenges;
      case TRANSITION_OBJECTS_TYPES.NEWS:
        return isFetchingNextPageNews;
      default:
        return false;
    }
  };

  const handlechoosePlace = (
    place: IMarathonSpaceSingle | IShowcaseOfferDiscount | INews,
  ) => {
    // @ts-ignore
    const title = place.title || place.name;
    const object = {
      id: place.id,
      title,
    };

    choosePlace(place.id);
    setSelectedObject(object);
    hideSearchComponent();
  };

  const getAttributesFromSelectedPlace = useMemo(() => {
    if (!selectedPlace) {
      return {
        title: '',
      };
    }
    return {
      title: selectedPlace.title,
    };
  }, [selectedPlace]);

  return (
    <StyledContainer>
      <Popover
        id={showSearchComponent ? 'selectPlace' : undefined}
        open={showSearchComponent}
        anchorEl={anchorEl}
        onClose={hideSearchComponent}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledSearchComponent>
          <SearchInput
            handleSearch={search => handleSearch(search)}
            size='large'
          />
          {placeType === TRANSITION_OBJECTS_TYPES.OFFER ||
            (placeType === TRANSITION_OBJECTS_TYPES.DISCOUNT &&
              products?.pages.map(productPage => (
                <Fragment key={productPage.current_page}>
                  {productPage.data.map(product => (
                    <StyledSelectProductItem
                      key={product.id}
                      onClick={() => handlechoosePlace(product)}
                    >
                      <Box className='imageBox'>
                        <img src={product.cover_big} alt={product.title} />
                      </Box>
                      <span className='name'>{product.title}</span>
                    </StyledSelectProductItem>
                  ))}
                </Fragment>
              )))}
          {placeType === TRANSITION_OBJECTS_TYPES.MARATHON_TEAM &&
            challenges?.pages.map(challengePage => (
              <Fragment key={challengePage.current_page}>
                {challengePage.data.map(challenge => (
                  <StyledSelectProductItem
                    key={challenge.id}
                    onClick={() => handlechoosePlace(challenge)}
                  >
                    <Box className='imageBox'>
                      <img src={challenge.cover} alt={challenge.name} />
                    </Box>
                    <span className='name'>{challenge.name}</span>
                  </StyledSelectProductItem>
                ))}
              </Fragment>
            ))}
          {placeType === TRANSITION_OBJECTS_TYPES.NEWS &&
            news?.pages.map(newsPage => (
              <Fragment key={newsPage.current_page}>
                {newsPage.data.map(newsEl => (
                  <StyledSelectProductItem
                    key={newsEl.id}
                    onClick={() => handlechoosePlace(newsEl)}
                  >
                    <Box className='imageBox'>
                      <img src={newsEl.cover} alt={newsEl.name} />
                    </Box>
                    <span className='name'>{newsEl.name}</span>
                  </StyledSelectProductItem>
                ))}
              </Fragment>
            ))}
          {getHasNextPage() && (
            <Button
              className='paginationButton'
              color='customLightBlue'
              onClick={loadNextPage}
            >
              {getIsLoadingNextPage() ? 'Загрузка' : 'Загрузить ещё'}
            </Button>
          )}
        </StyledSearchComponent>
      </Popover>
      {selectedPlace ? (
        <StyledSelectedProduct>
          <Box className='data'>
            <span className='name'>{getAttributesFromSelectedPlace.title}</span>
          </Box>
          <DeleteIcon
            onClick={() => setSelectedObject(undefined)}
            color={COLORS.RED}
          />
        </StyledSelectedProduct>
      ) : (
        <Button
          onClick={openSearchComponent}
          className='selectButton'
          color='customLightBlue'
          size='large'
        >
          Выбрать{' '}
          {placeType === TRANSITION_OBJECTS_TYPES.OFFER
            ? 'предложение'
            : placeType === TRANSITION_OBJECTS_TYPES.DISCOUNT
            ? 'скидка'
            : placeType === TRANSITION_OBJECTS_TYPES.NEWS
            ? 'новость'
            : 'вызов'}
        </Button>
      )}
    </StyledContainer>
  );
};

import { FC, useMemo } from 'react';
import { ROUTES } from 'app';
import {
  AddEditNotificationDrawer,
  NotificationsTable,
  Layout,
  NotificationsHeader,
  ShowcasesInnerNavbar,
} from 'components';
import { useDrawer } from 'hooks';

export const CustomNotifications: FC = () => {
  const {
    openAddDrawer,
    editDrawerId,
    purpose,
    hideAddEditDrawer,
    showAddEditDrawer,
  } = useDrawer();

  const NOTIFICATIONS_INNER_NAVBAR_MENU_ITEMS = useMemo(
    () => [
      {
        title: 'Общие',
        path: ROUTES.notifications.general,
      },
      {
        title: 'Кастомные',
        path: ROUTES.notifications.custom,
      },
    ],
    [],
  );

  return (
    <Layout withoutPB>
      <AddEditNotificationDrawer
        open={showAddEditDrawer}
        onClose={hideAddEditDrawer}
        purpose={purpose}
        id={editDrawerId}
      />
      <NotificationsHeader openAddNotificationDrawer={openAddDrawer} />
      <ShowcasesInnerNavbar menuItems={NOTIFICATIONS_INNER_NAVBAR_MENU_ITEMS} />
      <NotificationsTable />
    </Layout>
  );
};

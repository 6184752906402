import { FC, useState, useEffect } from 'react';
import { Drawer, DrawerHeader, ModalTitle, StyledFlexBox, Button } from 'UI';
import { CloseIcon, PlusIcon } from 'assets';
import {
  useGetMarathon,
  useCreatePrize,
  useUpdatePrize,
  useDrawer,
} from 'hooks';
import {
  CreatePrizeForChallenge,
  CreateAchievementForChallenge,
} from 'components';
import { IPrizeItem, IPrize } from 'types';
import { COLORS, CREATE_CHALLENGE_DRAWER_STEPS } from 'utils';

import { StyledBody, AddButtonContainer } from './styledComponents';
import { PlaceItem } from './PlaceItem';

const DEFAULT_PRIZES_INFO: IPrize = {
  id: 0,
  cover: '',
  type: 1,
  // required_points: 0,
  place_in_the_ladder: 1,
  items: [],
};

interface ICreateChallengeAddPrizesProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  goPreviousStep: () => void;
  marathonId: string;
}

export const CreateChallengeAddPrizes: FC<ICreateChallengeAddPrizesProps> = ({
  open,
  onClose,
  onSubmit,
  goPreviousStep,
  marathonId,
}) => {
  const {
    subDrawer,
    hideSubDrawer,
    showSubEditDrawer,
    subPurpose,
    changeSubDrawer,
  } = useDrawer();
  const { data: marathonInfo } = useGetMarathon(marathonId);
  const [prizesInfo, setPrizesInfo] = useState<IPrize[]>([]);
  const [editingPrizePlace, setEditingPrizePlace] = useState<number>(-1);
  const [editingItemInfo, setEditingItemInfo] = useState<
    IPrizeItem | undefined
  >(undefined);

  const { mutateAsync: createPrizes } = useCreatePrize();
  const { mutateAsync: updatePrizes } = useUpdatePrize();

  useEffect(() => {
    // Place to set prizes if editing
    if (marathonInfo?.trophies) {
      setPrizesInfo(marathonInfo.trophies);
    }
  }, [marathonInfo]);

  const addPlace = (type: 1 | 2) => {
    // Only one for participating
    if (type === 2 && prizesInfo.some(prize => prize.type === 2)) return;
    // how many places are already occupied
    const ladderPlace = prizesInfo.filter(prize => prize.type === 1).length;
    const newPrize = {
      ...DEFAULT_PRIZES_INFO,
      type,
      // 0 - for participating, ladderPlace + 1 - for place
      place_in_the_ladder: type === 1 ? ladderPlace + 1 : 0,
      marathon_id: marathonId,
    };
    setPrizesInfo([...prizesInfo, newPrize]);
  };

  const deletePlace = (place_in_the_ladder: number) => {
    setPrizesInfo(prevPrizesInfo =>
      prevPrizesInfo.filter(
        prize => prize.place_in_the_ladder !== place_in_the_ladder,
      ),
    );
  };

  const movePlacePrizesUp = (place_in_the_ladder: number) => {
    const updatedPrizes = [...prizesInfo];
    const goingUpItem = updatedPrizes.find(
      prize => prize.place_in_the_ladder === place_in_the_ladder,
    );
    if (!goingUpItem) return;
    const goingDownItem = updatedPrizes[updatedPrizes.indexOf(goingUpItem) - 1];
    if (!goingDownItem) return;
    const copyOfGoingUpItem = { ...goingUpItem };
    goingUpItem.items = [...goingDownItem.items];
    goingDownItem.items = [...copyOfGoingUpItem.items];
    setPrizesInfo(updatedPrizes);
  };

  const movePlacePrizesDown = (place_in_the_ladder: number) => {
    const updatedPrizes = [...prizesInfo];
    const goingDownItem = updatedPrizes.find(
      prize => prize.place_in_the_ladder === place_in_the_ladder,
    );
    if (!goingDownItem) return;
    const goingUpItem = updatedPrizes[updatedPrizes.indexOf(goingDownItem) + 1];
    if (!goingUpItem) return;
    const copyOfGoingUpItem = { ...goingUpItem };
    goingUpItem.items = [...goingDownItem.items];
    goingDownItem.items = [...copyOfGoingUpItem.items];
    setPrizesInfo(updatedPrizes);
  };

  const openPrizeDrawer = (
    place_in_the_ladder: number,
    editingItem?: IPrizeItem,
  ) => {
    setEditingPrizePlace(place_in_the_ladder);
    setEditingItemInfo(editingItem);
    changeSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.CREATE_PRIZE);
  };

  const openAchievementDrawer = (
    place_in_the_ladder: number,
    editingItem?: IPrizeItem,
  ) => {
    setEditingPrizePlace(place_in_the_ladder);
    setEditingItemInfo(editingItem);
    changeSubDrawer(CREATE_CHALLENGE_DRAWER_STEPS.CREATE_ACHIEVEMENT);
  };

  const handlePlaceParameterChange = <T extends keyof IPrize>(
    place_in_the_ladder: number,
    fieldName: T,
    value: IPrize[T],
  ) => {
    const updatedPrizes = [...prizesInfo];
    const place = updatedPrizes.find(
      pl => pl.place_in_the_ladder === place_in_the_ladder,
    );
    if (!place) return;
    // if (NUMBER_PARAMETERS.includes(fieldName) && !isStringNumber(value)) {
    //   return;
    // }
    place[fieldName] = value;
    setPrizesInfo(updatedPrizes);
  };

  const deletePrize = (place_in_the_ladder: number, index: number) => {
    const updatedPrizes = [...prizesInfo];
    const place = updatedPrizes.find(
      pl => pl.place_in_the_ladder === place_in_the_ladder,
    );
    const item = place?.items[index];
    if (!place || !item) return;
    place.items = place.items.filter((_, i) => i !== index);
    setPrizesInfo(updatedPrizes);
  };

  const addPrizeToPlace = (
    prize: IPrizeItem,
    place_in_the_ladder?: number,
    editingItemPosition?: number,
  ) => {
    const checkedIndex = place_in_the_ladder ?? editingPrizePlace;
    const checkedPosition =
      editingItemPosition || editingItemInfo?.position || 0;
    const updatedPrizes = [...prizesInfo];
    const place = updatedPrizes.find(
      pl => pl.place_in_the_ladder === checkedIndex,
    );
    if (!place) {
      closeDrawers();
      return;
    }
    const editItInfo = prizesInfo
      .find(pr => pr.place_in_the_ladder === checkedIndex)
      ?.items.find(item => item.position === checkedPosition);
    if (editItInfo) {
      place.items = place.items.map(item =>
        item.position === editItInfo.position ? prize : item,
      );
      setPrizesInfo(updatedPrizes);
      setEditingItemInfo(undefined);
      closeDrawers();
      return;
    }
    place.items = [
      ...place.items,
      { ...prize, position: place.items.length + 1 },
    ];
    setPrizesInfo(updatedPrizes);
    closeDrawers();
  };

  const closeDrawers = () => {
    setEditingPrizePlace(-1);
    setEditingItemInfo(undefined);
    hideSubDrawer();
  };

  const saveChanges = () => {
    if (marathonInfo?.trophies && marathonInfo?.trophies.length > 0) {
      updatePrizes({
        marathon_id: marathonId,
        data: prizesInfo,
      })
        .then(() => {
          onSubmit();
        })
        .catch(() => {});
      return;
    }
    createPrizes({
      marathon_id: marathonId,
      data: prizesInfo,
    })
      .then(() => {
        onSubmit();
      })
      .catch(() => {});
  };

  const ignoreChanges = () => {
    onClose();
  };

  return (
    <>
      <CreatePrizeForChallenge
        open={
          subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_PRIZE ||
          (showSubEditDrawer &&
            subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_PRIZE)
        }
        onClose={closeDrawers}
        purpose={subPurpose}
        onSubmit={addPrizeToPlace}
        editingItem={editingItemInfo}
      />
      <CreateAchievementForChallenge
        open={
          subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_ACHIEVEMENT ||
          (showSubEditDrawer &&
            subDrawer === CREATE_CHALLENGE_DRAWER_STEPS.CREATE_ACHIEVEMENT)
        }
        onClose={closeDrawers}
        purpose={subPurpose}
        onSubmit={addPrizeToPlace}
        editingItem={editingItemInfo}
      />
      <Drawer maxWidth='50vw' open={open} onClose={onClose}>
        <DrawerHeader>
          <ModalTitle title='Выбор призовых мест' />
          <CloseIcon onClick={ignoreChanges} />
        </DrawerHeader>
        <StyledBody>
          {prizesInfo.map(prize => (
            <PlaceItem
              key={prize.place_in_the_ladder}
              placeInfo={prize}
              deletePlace={deletePlace}
              movePlacePrizesUp={movePlacePrizesUp}
              movePlacePrizesDown={movePlacePrizesDown}
              openPrizeDrawer={openPrizeDrawer}
              openAchievementDrawer={openAchievementDrawer}
              deletePrize={deletePrize}
              addPrize={addPrizeToPlace}
              handleParameterChange={handlePlaceParameterChange}
            />
          ))}
          <AddButtonContainer>
            <Button
              variant='contained'
              size='large'
              color='customLightBlue'
              startIcon={<PlusIcon color={COLORS.BLUE} />}
              onClick={() => addPlace(1)}
            >
              Добавить место
            </Button>
          </AddButtonContainer>
          <AddButtonContainer>
            <Button
              variant='contained'
              size='large'
              color='customLightBlue'
              startIcon={<PlusIcon color={COLORS.BLUE} />}
              onClick={() => addPlace(2)}
            >
              За участие
            </Button>
          </AddButtonContainer>
        </StyledBody>
        <StyledFlexBox className='buttons'>
          <Button
            variant='contained'
            size='large'
            onClick={goPreviousStep}
            color='customGray'
          >
            Назад
          </Button>
          <Button variant='contained' size='large' onClick={saveChanges}>
            Далее
          </Button>
        </StyledFlexBox>
      </Drawer>
    </>
  );
};

import { FC } from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { IHeadCell, INotification } from 'types';

const headCells: readonly IHeadCell<INotification>[] = [
  {
    id: 'title',
    label: 'Название',
    sortable: false,
  },
  {
    id: 'body',
    label: 'Описание',
    sortable: false,
  },
  {
    id: 'view_count',
    label: 'Кол-во переходов',
    sortable: false,
  },
  {
    id: 'actions',
    label: 'Управление',
    sortable: false,
    align: 'center',
  },
];

export const NotificationsTableHead: FC = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align || 'left'}
              padding='normal'
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

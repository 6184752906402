import { FC, useContext, useEffect, useState } from 'react';
import md5 from 'md5';
import { useLocation } from 'react-router-dom';
import { Popover } from '@mui/material';
import { UserContext, UserContextType, ROUTES } from 'app';
import { Button } from 'UI';
import {
  getAnalyticsNewsDownloadFile,
  getAnalyticsUsersDetailedDownloadFile,
  getAnalyticsShowcasePartnersDownloadFile,
  getAnalyticsShowcaseClientsDownloadFile,
} from 'api';
import { FilterContext, fileDownloadByLink, notify } from 'utils';
import { DoneIcon, DownloadIcon, LoadingIcon } from 'assets';

import { LoaderContainer, StyledPopoverContainer } from './styledComponents';
import { getUsersFileLinkFromSocket, IReportDownloadProps } from './utils';

const BUTTON_CONFIG = {
  variant: 'contained' as const,
  color: 'customWhite' as const,
  size: 'medium' as const,
};

export const ReportDownload: FC<IReportDownloadProps> = ({
  showPopover,
  anchorEl,
  handleClose,
}) => {
  const { pathname } = useLocation();
  const { space_id, userData } = useContext(UserContext) as UserContextType;
  const { startDate, endDate } = useContext(FilterContext);
  const [isSocketLoading, setIsSocketLoading] = useState<boolean>(false);
  const [isFileReady, setIsFileReady] = useState<boolean>(false);
  const [userFileLink, setUserFileLink] = useState<string>('');

  useEffect(() => {
    setIsFileReady(false);
    setUserFileLink('');
  }, [startDate, endDate, space_id]);

  const downloadShowcasePartners = () => {
    getAnalyticsShowcasePartnersDownloadFile({
      start_date: startDate,
      end_date: endDate,
    })
      .then(res => {
        fileDownloadByLink(res);
      })
      .catch(() => {})
      .finally(() => handleClose());
  };

  const downloadShowcaseClients = () => {
    getAnalyticsShowcaseClientsDownloadFile({
      start_date: startDate,
      end_date: endDate,
    })
      .then(res => {
        fileDownloadByLink(res);
      })
      .catch(() => {})
      .finally(() => handleClose());
  };

  const downloadNews = () => {
    getAnalyticsNewsDownloadFile({
      start_date: startDate,
      end_date: endDate,
    })
      .then(res => {
        fileDownloadByLink(res);
      })
      .catch(() => {})
      .finally(() => handleClose());
  };

  const prepareUsersFile = () => {
    setIsSocketLoading(true);
    getAnalyticsUsersDetailedDownloadFile({
      start_date: startDate,
      end_date: endDate,
    })
      .then(() => {
        const hash = md5(md5(userData!.id.toString()));
        getUsersFileLinkFromSocket(hash)
          .then(link => {
            setUserFileLink(link);
            setIsFileReady(true);
          })
          .catch(() => {
            notify('Ошибка при загрузке файла', 'error');
          })
          .finally(() => {
            setIsSocketLoading(false);
          });
      })
      .catch(() => {});
  };

  const downloadUsersFile = () => {
    fileDownloadByLink(userFileLink);
    handleClose();
  };

  const getEndIcon = () => {
    if (isSocketLoading) {
      return (
        <LoaderContainer>
          <LoadingIcon />
        </LoaderContainer>
      );
    }
    if (isFileReady) {
      return <DoneIcon />;
    }
    return null;
  };

  return (
    <Popover
      id={showPopover ? 'downloadReportPopover' : undefined}
      open={showPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {pathname === ROUTES.analytics.showcases && (
        <StyledPopoverContainer>
          <Button {...BUTTON_CONFIG} onClick={downloadShowcasePartners}>
            Скачать информацию о партнерах
          </Button>
          <Button {...BUTTON_CONFIG} onClick={downloadShowcaseClients}>
            Скачать информацию о клиентах
          </Button>
        </StyledPopoverContainer>
      )}
      {pathname === ROUTES.analytics.users && (
        <StyledPopoverContainer>
          <Button
            {...BUTTON_CONFIG}
            onClick={prepareUsersFile}
            endIcon={getEndIcon()}
          >
            Подготовить отчет
          </Button>
          <Button
            {...BUTTON_CONFIG}
            startIcon={<DownloadIcon opacity={!isFileReady ? 0.5 : 1} />}
            disabled={!isFileReady}
            onClick={downloadUsersFile}
          >
            Скачать отчет
          </Button>
        </StyledPopoverContainer>
      )}
      {pathname === ROUTES.analytics.news && (
        <StyledPopoverContainer>
          <Button {...BUTTON_CONFIG} onClick={downloadNews}>
            Скачать отчет
          </Button>
        </StyledPopoverContainer>
      )}
    </Popover>
  );
};

export const fileDownloadByBlob = (data: BlobPart, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.setAttribute('href', url || '');
  link.setAttribute('target', '_blank');
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const fileDownloadByLink = (link: string, fileName?: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', link || '');
  a.setAttribute('target', '_blank');
  if (fileName) {
    a.setAttribute('download', fileName);
  }
  a.click();
  a.remove();
};

import { FC } from 'react';
import { ROLES } from 'utils';
import {
  AchievementsIcon,
  AnalyticsIcon,
  ChallengesIcon,
  GroupsIcon,
  // LevelsIcon,
  NewsIcon,
  ModerationIcon,
  // PartnersIcon,
  ShowcaseIcon,
  ShopIcon,
  SpacesIcon,
  TeamsIcon,
  UsersIcon,
  EventLogIcon,
  NotificationsIcon,
  ServicesIcon,
  // ApplicationsIcon,
  WorkersIcon,
  WorkoutsIcon,
  BannersIcon,
  // StandardsIcon,
  WorkoutsCalendarIcon,
  StoriesIcon,
} from './icons';
import { IIconProps } from './icons/types';

export interface IMenuItem {
  title: string;
  path: string;
  icon: FC<IIconProps>;
  roles: number[];
}

export const MenuItems: IMenuItem[] = [
  {
    title: 'Аналитика',
    path: '/analytics',
    icon: AnalyticsIcon,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  {
    title: 'Пространства',
    path: '/spaces',
    icon: SpacesIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  // {
  //   title: 'Партнеры',
  //   path: '/partners',
  //   icon: PartnersIcon,
  //   roles: [ROLES.ADMINSTRATOR],
  // },
  {
    title: 'Витрина',
    path: '/showcases',
    icon: ShowcaseIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Магазин',
    path: '/shop',
    icon: ShopIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Баннеры',
    path: '/banners',
    icon: BannersIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Сторисы',
    path: '/stories',
    icon: StoriesIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Группы',
    path: '/groups',
    icon: GroupsIcon,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  {
    title: 'Новости',
    path: '/news',
    icon: NewsIcon,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  {
    title: 'Модерация',
    path: '/moderation',
    icon: ModerationIcon,
    roles: [ROLES.ADMINSTRATOR, ROLES.MODERATOR],
  },
  {
    title: 'Я и ГТО',
    path: '/services',
    icon: ServicesIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  // {
  //   title: 'Заявки',
  //   path: '/applications',
  //   icon: ApplicationsIcon,
  //   userOnly: true,
  // },
  {
    title: 'Сотрудники',
    path: '/workers',
    icon: WorkersIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Пользователи',
    path: '/users',
    icon: UsersIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Тренировки',
    path: '/workouts',
    icon: WorkoutsIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  // {
  //   title: 'Нормативы',
  //   path: '/standards',
  //   icon: StandardsIcon,
  //   roles: [ROLES.ADMINSTRATOR],
  // },
  {
    title: 'Уведомления',
    path: '/notifications',
    icon: NotificationsIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Вызовы',
    path: '/challenges',
    icon: ChallengesIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Достижения',
    path: '/achievements',
    icon: AchievementsIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Цели',
    path: '/calendarWorkouts',
    icon: WorkoutsCalendarIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Регионы',
    path: '/regions',
    icon: BannersIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Команды',
    path: '/teams',
    icon: TeamsIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
  {
    title: 'Лог событий',
    path: '/eventlog',
    icon: EventLogIcon,
    roles: [ROLES.ADMINSTRATOR],
  },
];

export const ROUTES = {
  initialRoute: '/',
  noMatch: '*',
  home: '/analytics',
  auth: '/auth',
  analytics: {
    get: '/analytics',
    spaces: '/analytics/spaces',
    users: '/analytics/users',
    challenges: '/analytics/challenges',
    workouts: '/analytics/workouts',
    teams: '/analytics/teams',
    news: '/analytics/news',
    showcases: '/analytics/showcases',
    services: '/analytics/services',
    full: '/full-analytics',
  },
  spaces: '/spaces',
  groups: '/groups',
  partners: '/partners',
  news: '/news',
  createNews: '/news/create',
  showNewsItem: '/news/show/:id',
  editNewsItem: '/news/edit/:id',
  showNewsComments: '/news/comments/:id',
  moderation: '/moderation',
  notifications: {
    get: '/notifications',
    general: '/notifications/general',
    custom: '/notifications/custom',
  },
  services: {
    get: '/services',
    instructions: '/services/instructions',
    meAndGto: '/services/me-and-gto',
    qa: '/services/qa',
  },
  banners: '/banners',
  levels: '/levels',
  showcases: {
    get: '/showcases',
    offerAndDiscounts: '/showcases/offer-and-discounts',
    discounts: '/showcases/discounts',
    offers: '/showcases/offers',
    categories: '/showcases/categories',
  },
  shop: {
    get: '/shop',
    products: '/shop/products',
    createProduct: '/shop/products/edit',
    categories: '/shop/categories',
    attributes: '/shop/attributes',
  },
  applications: '/applications',
  users: '/users',
  workers: '/workers',
  workouts: '/workouts',
  workoutsCalendar: '/calendarWorkouts',
  standards: '/standards',
  achievements: '/achievements',
  challenges: '/challenges',
  teams: '/teams',
  eventlog: '/eventlog',
  regions: '/regions',
  stories: '/stories',
};

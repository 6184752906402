import { FC, useState, useMemo, useContext } from 'react';
import { TableBody, TableCell } from '@mui/material';

import { EditIcon, DeleteIcon } from 'assets';
import { Pagination, StyledSwitch } from 'UI';
import {
  useGetNotifications,
  useDeleteNotification,
  useSearchParams,
  useChangeStatus,
} from 'hooks';
import { INotification } from 'types';
import { DeleteModal } from 'components';
import { UserContext, UserContextType } from 'app';

import {
  StyledBox,
  StyledPaper,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
} from './styledComponents';
import { NotificationsTableHead } from './NotificationsTableHead';

export const NotificationsTable: FC = () => {
  const { space_id } = useContext(UserContext) as UserContextType;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<
    INotification | undefined
  >(undefined);
  const { setParam, getParam, searchParams } = useSearchParams();

  const params = useMemo(() => {
    return {
      page: getParam('page'),
      search: getParam('search'),
    };
  }, [searchParams]);

  const {
    data: { data: notifications, last_page: pageCount } = {
      data: [],
      last_page: 1,
    },
  } = useGetNotifications(params);
  const { mutateAsync: deleteNotification } = useDeleteNotification();
  const { mutateAsync: changeStatus } = useChangeStatus('notification');

  const updateRow = (row: INotification) => {
    changeStatus({
      space_id: space_id!,
      model_name: 'notification',
      model_id: row.id,
      state: !row.is_active,
    })
      .then(() => {})
      .catch(() => {});
  };

  const deleteButtonHandler = (row: INotification) => {
    setSelectedNotification(row);
    setOpenDeleteModal(true);
  };

  const deleteRow = async () => {
    await deleteNotification(selectedNotification!.id).catch(() => {});
    setOpenDeleteModal(false);
  };

  const cancelDeleteRow = () => {
    setOpenDeleteModal(false);
    setSelectedNotification(undefined);
  };

  const openEditDrawer = (id: string | number) => {
    setParam('edit', id.toString());
  };

  return (
    <StyledBox>
      <DeleteModal
        open={openDeleteModal}
        title='Удалить уведомление'
        description='Вы действительно хотите удалить уведомление?'
        onConfirm={deleteRow}
        onClose={cancelDeleteRow}
      />
      <StyledPaper>
        <StyledTableContainer>
          <StyledTable
            aria-labelledby='notificationsTable'
            size='medium'
            stickyHeader
          >
            <NotificationsTableHead />
            <TableBody>
              {notifications.map(row => {
                return (
                  <StyledTableRow hover key={row.id}>
                    <TableCell
                      component='th'
                      id={row.id.toString()}
                      scope='row'
                    >
                      <span>{row.title}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.body}</span>
                    </TableCell>
                    <TableCell>
                      <span>{row.view_count}</span>
                    </TableCell>
                    <TableCell>
                      <div className='editDeleteIcons'>
                        <StyledSwitch
                          checked={row.is_active}
                          variant='customGray'
                          onChange={() => updateRow(row)}
                          style={{ opacity: 1 }}
                        />
                        <EditIcon onClick={() => openEditDrawer(row.id)} />
                        <DeleteIcon onClick={() => deleteButtonHandler(row)} />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <Pagination count={pageCount} />
      </StyledPaper>
    </StyledBox>
  );
};

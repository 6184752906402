import { FC, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import {
  Drawer,
  DrawerHeader,
  DrawerFormBody,
  ModalTitle,
  Button,
  StyledFlexBox,
  FormItem,
  StyledSwitch,
  DateTimeInput,
  SearchSelectInput,
} from 'UI';
import { CloseIcon } from 'assets';
import { INotificationReq, NotificationTransitionType } from 'types';
import {
  useGetNotification,
  useCreateNotification,
  useUpdateNotification,
} from 'hooks';
import { StyledSwitchFormControl } from './styledComponents';
import { TRANSITION_TYPE_OPTIONS, TRANSITION_WITH_OBJECTS } from './utils';
import { ChooseTransitionObject } from './ChooseTransitionObject';

const TRANSLATIONS = {
  add: {
    title: 'Новое уведомление',
  },
  edit: {
    title: 'Редактирование уведомления',
  },
};

const DEFAULT_VALUES = {
  title: '',
  body: '',
  transition_type: 'main' as const,
  transition_target: 0,
  is_active: true,
  sent_at: '',
};

interface IAddEditNotificationDrawerProps {
  open: boolean;
  onClose: () => void;
  purpose: 'add' | 'edit';
  id: string;
}

export const AddEditNotificationDrawer: FC<IAddEditNotificationDrawerProps> = ({
  open,
  onClose,
  purpose = 'add',
  id = '',
}) => {
  const { data: notificationInfoData } = useGetNotification(id);
  const [notificationInfo, setNotificationInfo] =
    useState<INotificationReq>(DEFAULT_VALUES);

  const { mutateAsync: createNotification } = useCreateNotification();
  const { mutateAsync: updateNotification } = useUpdateNotification();

  useEffect(() => {
    if (purpose === 'edit') {
      setNotificationInfo({
        id: notificationInfoData?.id,
        title: notificationInfoData?.title || '',
        body: notificationInfoData?.body || '',
        is_active: !!notificationInfoData?.is_active,
        transition_type: notificationInfoData?.transition_type!,
        transition_target: notificationInfoData?.transition_target || 0,
        sent_at: notificationInfoData?.sent_at || '',
        transition_object: notificationInfoData?.transition_object,
      });
    } else {
      setNotificationInfo(DEFAULT_VALUES);
    }
  }, [purpose, notificationInfoData]);

  const handleFieldChange = <T extends keyof INotificationReq>(
    fieldName: T,
    newValue: INotificationReq[T],
  ) => {
    setNotificationInfo(prevNotificationInfo => ({
      ...prevNotificationInfo,
      [fieldName]: newValue,
    }));
  };

  const ignoreChanges = () => {
    setNotificationInfo(DEFAULT_VALUES);
    onClose();
  };
  const saveChanges = () => {
    if (purpose === 'add') {
      createNotification(notificationInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    } else {
      updateNotification(notificationInfo)
        .then(() => {
          onClose();
        })
        .catch(() => {});
    }
  };

  return (
    <Drawer open={open} onClose={ignoreChanges}>
      <DrawerHeader>
        <ModalTitle title={TRANSLATIONS[purpose].title} />
        <CloseIcon onClick={ignoreChanges} />
      </DrawerHeader>
      <DrawerFormBody>
        <h2>Основная информация</h2>
        <FormItem label='Заголовок'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите заголовок'
            name='title'
            value={notificationInfo.title}
            onChange={e => handleFieldChange('title', e.target.value)}
          />
        </FormItem>
        <FormItem label='Описание'>
          <TextField
            variant='standard'
            color='primary'
            placeholder='Введите описание'
            name='description'
            value={notificationInfo.body}
            onChange={e => handleFieldChange('body', e.target.value)}
          />
        </FormItem>
        <h2>Дата и время</h2>
        <DateTimeInput
          dateLabel='Дата отправки'
          timeLabel='Время отправки'
          defaultValue={notificationInfo.sent_at}
          onChange={val => handleFieldChange('sent_at', val)}
        />
        <FormItem label='Переход по клику'>
          <SearchSelectInput
            options={TRANSITION_TYPE_OPTIONS}
            value={notificationInfo?.transition_type}
            onChange={newValue =>
              handleFieldChange(
                'transition_type',
                newValue as NotificationTransitionType,
              )
            }
            variant='standard'
            placeholder='Выберите способ'
          />
        </FormItem>
        {TRANSITION_WITH_OBJECTS.includes(
          notificationInfo.transition_type as NotificationTransitionType,
        ) && (
          <ChooseTransitionObject
            placeType={notificationInfo.transition_type}
            targetId={notificationInfo.transition_target}
            choosePlace={newValue =>
              handleFieldChange('transition_target', newValue as number)
            }
            defaultSelectedObject={notificationInfo.transition_object}
          />
        )}
        <StyledSwitchFormControl
          label='Статус'
          labelPlacement='start'
          sx={{ marginBottom: '1.25rem' }}
          control={
            <StyledSwitch
              checked={notificationInfo.is_active}
              onChange={() =>
                handleFieldChange('is_active', !notificationInfo.is_active)
              }
            />
          }
        />
      </DrawerFormBody>
      <StyledFlexBox className='buttons fullDivWidth'>
        <Button
          onClick={ignoreChanges}
          variant='contained'
          size='large'
          color='customGray'
        >
          Отмена
        </Button>
        <Button variant='contained' size='large' onClick={saveChanges}>
          Сохранить
        </Button>
      </StyledFlexBox>
    </Drawer>
  );
};

import * as Pages from 'pages';
import { ROLES } from 'utils';
import { IRoutes } from '../types';

export const Notifications: IRoutes = {
  notifications: {
    path: '/notifications',
    title: 'notifications',
    element: <Pages.Notifications />,
    roles: [ROLES.ADMINSTRATOR],
  },
  customNotifications: {
    path: '/notifications/custom',
    title: 'notifications custom',
    element: <Pages.CustomNotifications />,
    roles: [ROLES.ADMINSTRATOR],
  },
  generalNotifications: {
    path: '/notifications/general',
    title: 'notifications general',
    element: <Pages.GeneralNotifications />,
    roles: [ROLES.ADMINSTRATOR],
  },
};

import { FC, useState } from 'react';
import { styled } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import { Button, PageHeader, StyledSwitch } from 'UI';
import { PlusIcon } from 'assets';

const StyledSwitchFormControl = styled(FormControlLabel)({
  display: 'flex',
  gap: '0.75rem',
  width: '100%',
  margin: 0,
  paddingBottom: '0.375rem',
  '& .MuiFormControlLabel-label': {
    marginTop: '0.375rem',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    fontWeight: 500,
  },
});

interface INotificationsHeaderProps {
  openAddNotificationDrawer: () => void;
}

export const NotificationsHeader: FC<INotificationsHeaderProps> = ({
  openAddNotificationDrawer,
}) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <PageHeader title='Уведомления'>
      <StyledSwitchFormControl
        label='Кастомные уведомления'
        labelPlacement='start'
        control={
          <StyledSwitch
            checked={checked}
            onChange={() => setChecked(prevChecked => !prevChecked)}
            variant='customGreen'
          />
        }
      />
      <Button
        variant='contained'
        onClick={openAddNotificationDrawer}
        startIcon={<PlusIcon />}
      >
        Добавить
      </Button>
    </PageHeader>
  );
};

/* eslint-disable no-console */
import { io } from 'socket.io-client';
import { notify } from 'utils';

export interface IReportDownloadProps {
  showPopover: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

export const getUsersFileLinkFromSocket = (
  channelId: string,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    // Create a WebSocket connection to the specified URL
    const socketUrl = `https://${process.env.REACT_APP_SOCKET_URL}`;
    const socket = io(socketUrl, {
      query: {
        channel: `gto_socket_channel_user_detailed_report_${channelId}`,
      },
    });

    socket.on('connect', () => {
      console.log('Socket.io connection established');
    });

    socket.onAny((eventName, ...args) => {
      if (
        eventName === `gto_socket_channel_user_detailed_report_${channelId}`
      ) {
        const { data } = args[0];
        resolve(data);
        socket.disconnect();
      }
    });

    // Handle connection errors
    socket.on('connect_error', error => {
      console.error('Socket.io connection error:', error);
      notify('Ошибка при подключении к сокету', 'error');
      reject(error);
    });

    // Handle general errors
    socket.on('error', error => {
      console.error('Socket.io error:', error);
      notify('Ошибка при работе с сокетом', 'error');
      reject(error);
    });

    // On socket disconnection
    socket.on('disconnect', () => {
      console.log('Socket.io connection disconnected');
    });
  });
};

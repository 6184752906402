import { NotificationTransitionType } from 'types';

export interface IOption {
  value: NotificationTransitionType;
  label: string;
}

export const TRANSITION_TYPE_OPTIONS: Array<IOption> = [
  {
    value: 'main',
    label: 'Главная',
  },
  {
    value: 'activity',
    label: 'Активность',
  },
  {
    value: 'marathon_individual',
    label: 'Вызов (личный)',
  },
  {
    value: 'marathon_team',
    label: 'Вызов (командный)',
  },
  {
    value: 'news',
    label: 'Новость',
  },
  {
    value: 'offer',
    label: 'Витрина (предложение)',
  },
  {
    value: 'discount',
    label: 'Витрина (скидка)',
  },
  {
    value: 'gto',
    label: 'ГТО',
  },
];

export const TRANSITION_WITH_OBJECTS: Array<NotificationTransitionType> = [
  'marathon_team',
  'offer',
  'discount',
  'news',
];

export const TRANSITION_OBJECTS_TYPES = {
  MARATHON_TEAM: 'marathon_team',
  OFFER: 'offer',
  DISCOUNT: 'discount',
  NEWS: 'news',
};
